import React, { useState, useEffect } from 'react'
import pkg from '../package.json'
import './App.css'
import { Route, Switch, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import { Navbar, Banner } from './components/header'
import { Profile, ProfileBeta } from './components/account'
import {
  Site,
  SiteBeta,
  Sites,
  SitesBeta,
  ViewHistory,
  ViewHistoryBeta
} from './components/sites'
import {
  Registry,
  Registries,
  RegistryBeta,
  RegistriesBeta,
} from './components/registries'
import { Home, HomeBeta } from './components/misc'
import { AdLoader, AdLoaderBeta, AdLoaderHistory, AdLoaderHistoryBeta } from './components/adLoader'
import { setUser, checkUser } from './modules/authSlice'
import {
  AdFuelVersions,
  PlatformVersions,
  AdFuelModules,
  AdFuelOptions,
  AdFuelDisablement,
  AdLoaderCDNFolders,
  BannerMessage,
  EmailUsers,
  ReportNotifications,
  SiteCategories,
  SlotTypes,
  SlotLocations,
  DFPCache,
  GoogleAdManagerAPI,
  GoogleAdManagerAPIBeta,
  Permissions,
  Roles,
  Users,
  UsersBeta,
  PublisherRoot,
  PageTemplates
} from './components/admin'
import {
  CDNPurgeTool,
  CDNPurgeToolBeta,
  DFPCustomTargets,
  DFPCustomTargetsBeta,
  POSTargetingReport,
  POSTargetingReportBeta,
  BulkLineItemCreation,
  BulkLineItemCreationBeta
} from './components/resources'
import { Container, CssBaseline, Box, AppBar } from '@material-ui/core'
import { Footer } from './components/footer'
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
import { hasRole } from './utilities/helperFunctions'
import { warnerBrosDiscoveryTheme, darkWarnerBrosDiscoveryTheme } from './themes'
import { fetchBannerMessage } from './modules/bannerMessageSlice'
import axios from 'axios'

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.primary.main
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    flexGrow: 1,
    paddingTop: '48px',
    borderRadius: '0'
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100%'
  },
  'swal2-container': {
    zIndex: 99999
  }
}))

const Content = ({ user, banner }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  // A custom hook that builds on useLocation to parse
  // the query string for you.
  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const isServiceMonitor = async (to, from, next) => {
    console.log("args: ", { to, from, next })
    next.props({to, from})
  }
  const isAuthenticated = async (to, from, next) => {
    if (!user) {
      user = await checkUser()
    }
    if (
      banner &&
      Object.keys(banner).length === 0 &&
      banner.constructor === Object
    ) {
      dispatch(fetchBannerMessage())
    }
    if (user) {
      window.localStorage.setItem('adal.login.request', window.location.href)
      window.localStorage.setItem('redirectUri', window.location.href)
      next()
    } else {
      next.redirect('/')
    }
  }
  const hasAdminAccess = async (to, from, next) => {
    if (!user) {
      user = await checkUser()
    }
    if (hasRole(['admin'], user)) {
      next()
    } else {
      next.redirect('/')
    }
  }

  const hasSuperAdminAccess = async (to, from, next) => {
    if (!user) {
      user = await checkUser()
    } 
    if (hasRole(['super-admin'], user)) {
      next()
    } else {
      next.redirect('/')
    }
  }

  const hasAdLoaderAccess = async (to, from, next) => {
    if (!user) {
      user = await checkUser()
    }
    if (hasRole(['admin', 'ad-loader'], user)) {
      next()
    } else {
      next.redirect('/adLoader/history')
    }
  }
  const hasGAMAccess = async (to, from, next) => {
    if (!user) {
      user = await checkUser()
    }
    if (hasRole(['admin', 'line-items-publisher', 'dfp-user'], user)) {
      next()
    } else {
      next.redirect('/')
    }
  }

  return (
    <Container className={classes.container}>
      <GuardProvider>
        <Switch>
          <GuardedRoute
            guards={[isServiceMonitor]}
            path='/servmon'
            component={(props) => {
              const env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
              ? 'development'
              : (
                  document.location.hostname.startsWith('qa.')
                    ? 'qa'
                    : 'production'
                )
              const serviceInfo = { name: pkg.name, version: pkg.version, environment: env, node_env: process.env.NODE_ENV }
              console.log('props: ', props)
              return <div>{JSON.stringify(serviceInfo)}</div>
            }}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/support/cdnPurge'
            component={betaTest ? CDNPurgeToolBeta : CDNPurgeTool}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/support/customTargets'
            component={betaTest ? DFPCustomTargetsBeta : DFPCustomTargets}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasGAMAccess]}
            path='/support/bulkLineItem'
            component={betaTest ? BulkLineItemCreationBeta : BulkLineItemCreation}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/support/POSTargetingReport'
            component={betaTest ? POSTargetingReportBeta : POSTargetingReport}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/adFuelVersions'
            component={AdFuelVersions}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/platformVersions'
            component={PlatformVersions}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/adFuelModules'
            component={AdFuelModules}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/adFuelOptions'
            component={AdFuelOptions}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasSuperAdminAccess]}
            path='/admin/adFuelDisablement'
            component={AdFuelDisablement}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/cdnFolders'
            component={AdLoaderCDNFolders}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/bannerMessage'
            component={BannerMessage}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/emailUsers'
            component={EmailUsers}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/reports'
            component={ReportNotifications}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/permissions'
            component={Permissions}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/roles'
            component={Roles}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/templates'
            component={PageTemplates}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/categories'
            component={SiteCategories}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/slotTypes'
            component={SlotTypes}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/locations'
            component={SlotLocations}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/users'
            component={betaTest ? UsersBeta : Users}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/dfpAdmin'
            component={DFPCache}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasGAMAccess]}
            path='/support/gamAPI'
            component={betaTest ? GoogleAdManagerAPIBeta : GoogleAdManagerAPI}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdminAccess]}
            path='/admin/publisherRoot'
            component={PublisherRoot}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/adLoader/history'
            component={betaTest ? AdLoaderHistoryBeta : AdLoaderHistory}
          />
          <GuardedRoute
            guards={[isAuthenticated, hasAdLoaderAccess]}
            path='/adLoader'
            component={betaTest ? AdLoaderBeta : AdLoader}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/registry/:registryName/history'
            component={betaTest ? ViewHistoryBeta : ViewHistory}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/registry/:registryName'
            component={betaTest ? RegistryBeta : Registry}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/singleton/:registryName/history'
            component={betaTest ? ViewHistoryBeta : ViewHistory}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/singleton/:registryName'
            component={betaTest ? RegistryBeta : Registry}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/:registryName/history'
            component={betaTest ? ViewHistoryBeta : ViewHistory}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries/:registryName'
            component={betaTest ? RegistryBeta : Registry}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/registries'
            component={betaTest ? RegistriesBeta : Registries}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName/history'
            component={betaTest ? ViewHistoryBeta : ViewHistory}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites/:siteName'
            component={betaTest ? SiteBeta : Site}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/sites'
            component={betaTest ? SitesBeta : Sites}
          />
          <GuardedRoute
            guards={[isAuthenticated]}
            path='/profile'
            component={betaTest ? ProfileBeta : Profile}
          />
          <Route path='/api/aaa/export/users' component={() => {
            let env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
            ? 'development'
            : (
                document.location.hostname.startsWith('qa.')
                  ? 'qa'
                  : 'production'
              )
            let query = useQuery()
            console.log('query: ', query)
            let url = `https://${env === 'development' ? '' : env === 'production' ? 'prod.' : env + '.'}api.rocketeer.turnerapps.com/api/dfp/aaa/export/users?${query}`;
            console.log('Redirecting to: ', url)
            window.location.href = url
            return null;
          }} />
          <Route path='/' component={betaTest ? HomeBeta : Home} />
        </Switch>
      </GuardProvider>
    </Container>
  )
}

export const App = () => {
  const classes = useStyles()
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem('theme') === 'dark'
  )
  const [betaTest, setBetaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  const { banner } = useSelector(state => state.bannerMessageSlice)
  const { user } = useSelector(state => state.authReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setUser())
  }, [dispatch])

  return (
    <MuiThemeProvider theme={darkMode ? darkWarnerBrosDiscoveryTheme : warnerBrosDiscoveryTheme}>
      <CssBaseline />
      <Box>
        <AppBar>
          <Navbar darkMode={darkMode} setDarkMode={setDarkMode} betaTest={betaTest} setBetaTest={setBetaTest} />
        </AppBar>
        <main className={classes.content}>
          {!!banner.message && <Banner />}
          <Content user={user} banner={banner} />
        </main>
      </Box>
      <Footer />
    </MuiThemeProvider>
  )
}

import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaterialTable from "material-table";
import {  
  CustomDialog, 
  LoadingProgress,
  TableIcons, 
  ToggleSwitch
} from '../ui'
import SettingsIcon from '@material-ui/icons/Settings'
import MuiAlert from '@material-ui/lab/Alert'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  TextField
} from '@material-ui/core'

import { PlayArrowRounded, StopRounded, Whatshot } from '@material-ui/icons'

import { commonStyle } from '../../styles'

import { clone } from '../../utilities/helperFunctions.js'

import { 
  getAdFuelDisablementSiteList,
  saveAdFuelModuleConfiguration,
  saveAdFuelOptionFile
} from '../../modules/lookupsSlice.js'

export function AdFuelDisablement() {
  const commonClasses = commonStyle()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [dialog, setDialog] = useState({content:''})
  const [dialogIsOpen, setDialogIsOpen] = useState(false)

  const { adFuelDisablementList } = useSelector(state => state.lookups)
  const [siteData, setSiteData] = useState(clone(adFuelDisablementList))

  const showModifyDisablementDialog = (data) => {
    setDialog({
      size: 'sm',
      content: (
        <ModifyDisablementDialog data={data} setDialogIsOpen={setDialogIsOpen} updateSiteData={updateSiteData} />
      ),
      displayDialogAction: false
    })
    setDialogIsOpen(true)
  }

  const updateSiteData = (newRecord) => {
    setIsLoading(true)
    // let newSiteData = clone(siteData)
    // let oldRecord = newSiteData.find(x=>x._id === newRecord._id)
    
    // oldRecord.disablementEnabled = newRecord.disablementEnabled
    // oldRecord.disablementData = newRecord.disablementData

    // ALWAYS delete this key -- default state this key should not exist
    if (newRecord.configDoc.configuration.DISABLE_ADFUEL) { 
      delete(newRecord.configDoc.configuration.DISABLE_ADFUEL)
    }

    if (newRecord.disablementEnabled) {
      newRecord.configDoc.configuration.DISABLE_ADFUEL = newRecord.disablementData
    }

    const optFile = {
      name : newRecord.siteName, 
      options: newRecord.configDoc.configuration
    }
    console.log('---sending to saveAdFuelOptionFile', optFile)
    saveAdFuelOptionFile(optFile)
    .then((result) => {
      console.log('---Results of saveAdFuelOptionFile:', result)
      if (result.status === 200) {
        const updateDoc = {
          site: newRecord.configDoc.siteId,
          module: newRecord.configDoc.adFuelModuleId,
          key: newRecord.configDoc.key,
          config: newRecord.configDoc.configuration
        }
        console.log('---sending to saveAdFuelConfiguration', updateDoc)
        saveAdFuelModuleConfiguration(updateDoc)
        .then((result) => {
          console.log('---Results of saveAdFuelConfiguration:', result)
          if (result.status === 200) {
            let siteDataClone = clone(siteData)
            let recordClone = siteDataClone.find(x=>x._id === newRecord._id)
            
            recordClone.disablementEnabled = newRecord.disablementEnabled
            recordClone.disablementData = newRecord.disablementData
            dispatch(getAdFuelDisablementSiteList())
            setSiteData(siteDataClone)
          }
        })
      }
    })
    .finally(() => {
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (adFuelDisablementList.length === 0) {
      dispatch(getAdFuelDisablementSiteList())
    } else {
      console.log('Shrubbery?  No thanks, Ive already got one!')
      setSiteData(clone(adFuelDisablementList))
    }
    setIsLoading(false)
  }, [adFuelDisablementList])

  return (
    <Container maxWidth='xl' style={{cursor: isLoading ? 'wait' : 'default' }}>
        <Card>
            <CardHeader title='AdFuel Disablement' />
            <CardContent className={commonClasses.cardBkClr}>
                {isLoading
                  ? (
                    <LoadingProgress label='Loading' />
                    )
                  : (
                    <MaterialTable
                      titles={'Sites'}
                      icons={TableIcons}
                      data={ siteData }
                      options={{
                        emptyRowsWhenPaging: false,
                        pageSize: 10,
                        pageSizeOptions:[10,20,50],
                        selection:false,
                        headerStyle: {
                            fontWeight: 'bold'
                        }
                      }}
                      columns={[
                        { title: 'Site Name', field: 'siteName' },
                        { title: 'Site Description', field: 'siteDesc' },
                        { title: 'Disablement Enabled', 
                          field: 'disablementEnabled',
                          render: (params) => {
                            if (Boolean(params.disablementEnabled)) {
                              return <StopRounded style={{ color: 'red'}} />
                            } else {
                              return <PlayArrowRounded style={{ color: 'green'}} />
                            }
                          }
                        }
                      ]}
                      actions={[
                        {
                          icon: SettingsIcon,
                          tooltip: 'Modify',
                          onClick: (event, data) => {
                            showModifyDisablementDialog(data)
                          }
                        },
                      ]}
                    />
                  )
                }

                <CustomDialog
                  open={dialogIsOpen}
                  setOpenDialog={setDialogIsOpen}
                  title={dialog.title}
                  subtitle={dialog.subtitle}
                  content={dialog.content}
                  displayDialogAction={dialog.displayDialogAction}
                  customAction={dialog.customAction}
                  size={dialog.size}
                />

            </CardContent>
        </Card>
    </Container>
  )
}


function ModifyDisablementDialog({data, setDialogIsOpen, updateSiteData}) {
  const commonClasses = commonStyle()
  const swal = withReactContent(Swal)

  const [disablementEnabled, setDisablementEnabled] = useState( data.disablementEnabled )
  const [disablementData, setDisablementData] = useState(data.disablementData)
  const [testUrl, setTestUrl] = useState('')
  const [passFail, setPassFail] = useState(testDisablement(disablementData, testUrl))

  const handleEnable = (e) => {
    // Set data to 'true' if enabled
    if (disablementData==='' && e.target.checked) {
      setDisablementData(e.target.checked ? 'true':'false')
    }
    // if data is 'true' and we are disabling, emtpy data.
    if (disablementData==='true' && !e.target.checked) {
      setDisablementData('')
    }

    setDisablementEnabled(e.target.checked)
  }

  return (
    <Card>
    <CardHeader title={`Modify Disablement for [${data.siteName}]: ${data.siteDesc}`} />
    <Divider />
    <CardContent>
      <ToggleSwitch
        label='Enable Disablement'
        name='registrySingleton'
        offLabel='No'
        onLabel='Yes'
        checked={disablementEnabled}
        value={disablementEnabled}
        onChange={(e) => {
          handleEnable(e)
        }}
      />  
      <TextField
        fullWidth
        label='Criteria'
        multiline
        value={disablementData}
        variant='outlined'
        disabled={!disablementEnabled}
        onChange={event => {
          setDisablementData(event.target.value)
        }}
      />
    </CardContent>
    <Divider />
    <CardContent>
        <Grid container style={{ marginBottom: '10pt'}}>
          <Grid item md={4} xs={12}>
            <TextField
              style={{ width: '99%'}}
              label='URL'
              margin='dense'
              name='testUrl'
              defaultValue=''
              variant='outlined'
              onChange={event => {
                setTestUrl(event.target.value)
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant='contained'
              color='primary'
              className={[
                commonClasses.btnInfo,
                commonClasses.m_1
              ].join(' ')}
              startIcon={<Whatshot />}
              onClick={() => {
                let didItPass = testDisablement(disablementData, testUrl)
                setPassFail(didItPass)
              }}
            >
              Test
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              variant='contained'
              className= {
                passFail ? commonClasses.btnSuccess : commonClasses.btnDanger
              }
            >
              { disablementEnabled ?
                  passFail ? 
                    'Result: ADFUEL DISABLED' : 
                    'Result: ADFUEL NOT DISABLED'
                  : ''}
            </Box>
          </Grid>
        </Grid>        
    </CardContent>
    <Box mt={3}>
      <MuiAlert variant='filled' severity='info'>
        <List subheader='Note' dense>
          <ListItem dense>
            Value can be a boolean, a string, a regEx, or an array of strings and regExes.
          </ListItem>
          <ListItem dense>
            Strings require no quotes.
          </ListItem>
          <ListItem dense>
            A RegEx should be expressed like:  /ncaa\\.com(?:\\/d2(?:\\?.*)?)?(?:\\/(?:\\?.*)?)?$/  Notice the double backslash for single backslash.  
          </ListItem>
          <ListItem dense>
            An array should be expressed like:  ["test","me"].  Notice the double quotes.  
          </ListItem>

        </List>
      </MuiAlert>
    </Box>
    <Divider />
    <CardActions className={commonClasses.actionArea}>
      <Button variant='text' onClick={() => setDialogIsOpen(false)}>
        Cancel
      </Button>
      <Button
        className={commonClasses.btnSuccess}
        variant='contained'
        onClick={() => {
          setDialogIsOpen(false)
          swal.fire({
            title: 'Are you sure?',
            text: `${data.length} file(s) will be affected.`,
            icon: 'warning',
            showCancelButton: true
            // buttons: true,
            // dangerMode: true
          }).then(ok => {
            if (ok.isConfirmed) {
              updateSiteData({
                _id : data._id,
                siteName: data.siteName,
                siteDesc: data.siteDesc,
                disablementEnabled : disablementEnabled,
                disablementData : disablementData,
                configDoc: data.configDoc
              })
            } 
          })
        }}
      >
        Apply
      </Button>
    </CardActions>
  </Card>
  )


  function testDisablement(criteria, url) {
    let isIt = false
    const pageUrl = url

    if (String(criteria).toUpperCase()==='TRUE') criteria=true
    if (String(criteria).toUpperCase()==='FALSE') criteria=false
    if (String(criteria).startsWith('[') && String(criteria).endsWith(']') ) criteria = JSON.parse(criteria)

    let evalString = (criteriaString) => {
      if (/^\/.*\//.test(criteriaString)) {
        try {
          return new RegExp(criteriaString.slice(1,-1),'i').test(pageUrl)
        } catch (ex) {
          console.error('!!! AdFuel isDisabled RegExp threw:', ex)
          return false
        }
      } else {
        return pageUrl.toUpperCase().includes(criteriaString.toUpperCase())
      }
    } 

    if (typeof criteria == "boolean") {
      isIt = criteria
    } else if (typeof criteria === 'string') {
      isIt = evalString(criteria)
    } else  if (Array.isArray(criteria)) {
      for(let c of (criteria)) {
        if (evalString(c)) {
          isIt = true
          break
        }
      }
    }

    if (isIt) console.log(`!!! AdFuel would be disabled !!!`)
    return isIt
  }
}
import { createSlice } from '@reduxjs/toolkit'
import { hasRole } from '../utilities/helperFunctions'
import axios from 'axios'
let env = document.location.hostname.startsWith('rocketeer-ui-dev.') || document.location.hostname.startsWith('dev.') || document.location.hostname.startsWith('localhost') || document.location.hostname.startsWith('ui.')
  ? 'development'
  : (
      document.location.hostname.startsWith('qa.')
        ? 'qa'
        : 'production'
    )
if (document.location.hostname.startsWith('localhost')) env = 'local'

import config from '../config'
let envConfig = config[env]
let samlConfig = envConfig.saml

const initialState = {
  isLoginSuccess: false,
  isLoginPending: false,
  user: null,
  username: null,
  displayName: null,
  firstName: null,
  lastName: null,
  email: null,
  loginError: null,
  permissions: [],
  roles: [],
  canEditSite: false,
  canEditRegistry: false,
  canEditIVR: false,
  canUseAdLoader: false,
  canUseBulkLineItemCreation: false,
  hasDFPAccess: false,
  isAdmin: false,
  isSuperAdmin: false,
  isBetaTester: false,
  loading: false,
  error: ''
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    SET_LOGIN_SUCCESS: (state, action) => {
      state.isLoginSuccess = true
      state.isLoginPending = false
      state.user = action.payload.user
      state.username = action.payload.user.username
      state.displayName = action.payload.user.displayName
      state.firstName = action.payload.user.firstName
      state.lastName = action.payload.user.lastName
      state.permissions = action.payload.user.permissions
      state.roles = action.payload.user.role2
      state.email = action.payload.user.email
      state.canEditSite = hasRole(['admin', 'inview-refresh-editor', 'site-editor'], action.payload.user)
      state.canEditRegistry = hasRole(['admin', 'inview-refresh-editor', 'site-editor', 'registry-editor'], action.payload.user)
      state.hasDFPAccess = hasRole(['admin', 'dfp-user'], action.payload.user)
      state.canEditIVR = hasRole(['admin', 'inview-refresh-editor'], action.payload.user)
      state.canUseAdLoader = hasRole(['admin', 'ad-loader'], action.payload.user)
      state.canUseBulkLineItemCreation = hasRole(['admin', 'line-items-publisher'], action.payload.user)
      state.isAdmin = hasRole(['admin'], action.payload.user)
      state.isBetaTester = hasRole(['beta-tester'], action.payload.user)
      state.isSuperAdmin = hasRole(['super-admin'], action.payload.user)
    },
    SET_LOGIN_FAILURE: (state, action) => {
      state.loginError = true
      state.isLoginPending = false
      state.permissions = []
      state.error = action.payload.error
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})

export default authSlice.reducer

export const {
  SET_LOGIN_SUCCESS,
  SET_LOGIN_FAILURE,
  setLoading
} = authSlice.actions

export const setUser = () => {
  return dispatch => {
    dispatch(setLoading(true))
    return axios(samlConfig.whoamiUrl, { method: 'GET', withCredentials: true })
      .then(res => {
        dispatch(SET_LOGIN_SUCCESS({ user: res.data.user }))
      })
      .catch(err => {
        dispatch(SET_LOGIN_FAILURE({ error: err.toString() }))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

export const checkUser = () => {
  return axios(samlConfig.whoamiUrl, { method: 'GET', withCredentials: true })
    .then(res => {
      return res.data.user
    })
    .catch(err => console.log('checkUser WHOAMI ERR: ', err))
}

export const login = () => window.location.replace(samlConfig.loginUrl)
export const logout = () => {
  let theme = localStorage.getItem('theme')
  localStorage.clear()
  localStorage.setItem('theme', theme)
  window.location.href = samlConfig.logoutUrl
}

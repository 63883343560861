import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearSlots } from '../../modules/slotSlice'
import { ToggleIsAdFuelVersionSelected, fetchRocketeerSites } from '../../modules/sitesSlice'
import { useHistory, NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {
  TableFooter,
  TablePagination,
  CircularProgress,
  IconButton,
  makeStyles,
  Avatar,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Checkbox,
  Container,
  Breadcrumbs
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import {
  filterSiteRegistries,
  deleteSiteRegistry,
  deploySiteRegistry,
  deploySiteRegistries,
  addSiteRegistry,
  cloneRegistryResource,
  ToggleNewRegistryStatus,
  addNewRegistry,
  ToggleChildDirectedTreatmentDialog,
  ToggleInViewRefreshDialog,
  ToggleCustomTargetingDialog,
  CloseToast,
  updateSiteRegistrySettings
} from '../../modules/registrySlice'
import { fetchSlotTypes } from '../../modules/slotTypesSlice'
import { fetchSlotLocations } from '../../modules/slotLocationSlice'
import ChildDirectedTreatmentDialog from '../sites/Dialogs/ChildDirectedTreatmentDialog'
import CustomTargetingDialog from '../sites/Dialogs/CustomTargetingDialog'
import InViewRefreshDialog from '../sites/Dialogs/InViewRefreshDialog'
import { ManageSlotsDialog } from '../sites/Dialogs'

import {
  siteNameFormatter,
  registryNameFormatter
} from '../../utilities/helperFunctions'
import { addClasses, commonStyle } from '../../styles'
import { CustomDialog, TablePaginationActions, Toast, LoadingProgress } from '../ui'
import NewRegistryDialog from './newRegistryDialog'
import NewRegistryDialogBeta from './newRegistryDialogBeta'
import MuiAlert from '@material-ui/lab/Alert'

const swal = withReactContent(Swal)

const useStyles = makeStyles(theme => ({
  selectEmpty: { marginTop: theme.spacing(3) },
  spacer: { margin: theme.spacing(1) },
  root: { padding: theme.spacing(8) },
  input: { display: 'none' },
  successButton: { color: theme.palette.success.main },
  warningButton: { color: theme.palette.warning.main },
  infoButton: { color: theme.palette.info.main },
  errorButton: { color: theme.palette.error.dark },
  plainText: {
    fontSize: theme.typography.pxToRem(16)
  }
}))

const Registries = (props) => {
  const commonClasses = commonStyle()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { site, isAdFuelVersionSelected } = useSelector(state => state.sitesSlice)
  const history = useHistory()
  const pageTitle = site.name ? site.name + ' Registries' : 'Registries'
  const {
    registries,
    loading,
    isNew,
    newRegistryResource,
    isDeploying,
    isDeleting,
    toast,
    selectedItemId,
    isChildDirectedTreatmentDialogOpen,
    isInViewRefreshDialogOpen,
    isCustomTargetingDialogOpen
  } = useSelector(state => state.registrySlice)
  const { slotTypes } = useSelector(state => state.slotTypeSlice)
  const { locations } = useSelector(state => state.slotLocationSlice)
  const [searchTerm, setSearchTerm] = useState('')
  const [siteRegistries, setSiteRegistries] = useState([])
  const [selectedRegistries, setSelectedRegistries] = useState([])
  let isDownloadingSlotInfo = false
  const [isOnlyRegistriesNeedDeployed, setIsOnlyRegistriesNeedDeployed] = useState(false)
  const [isOnlySingletonRegistries, setIsOnlySingletonRegistries] = useState(false)
  const [isOnlyNonSingletonRegistries, setIsOnlyNonSingletonRegistries] = useState(false)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [openDialog, setOpenDialog] = useState(false)
  const [dialog, setDialog] = useState({ content: '' })
  const [loadingPage, setLoadingPage] = useState(true)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCloseMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(CloseToast())
  }

  // Filter
  const onSearchChange = e => {
    setSearchTerm(e.target.value)
    dispatch(filterSiteRegistries(e.target.value))
  }

  // Handle Changes
  const onRowClick = siteRegistry => {
    if (siteRegistry.isSingleton) { 
      history.push(
        `/sites/${siteNameFormatter(
          site.name
        )}/registries/singleton/${registryNameFormatter(siteRegistry.name, siteRegistry.templateName)}`
      )
    } else {
      history.push(
        `/sites/${siteNameFormatter(
          site.name
        )}/registries/registry/${registryNameFormatter(siteRegistry.name, siteRegistry.templateName)}`
      )
    }
  }

  const onDeleteRegistry = registryId => {
    swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, will not be able to recover this record!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willDelete => {
      if (willDelete.isConfirmed) {
        // setSelectedRegistry(registryId)
        dispatch(deleteSiteRegistry(registryId))
      }
    })
  }

  const onDeploySiteRegistry = registry => {
    dispatch(deploySiteRegistry(registry._id))
  }

  const onDeploySiteRegistries = () => {
    let message = `Are you sure to deploy ${siteRegistries.length} Registries?`
    if (selectedRegistries.length > 0) {
      message = `Are you sure to deploy ${selectedRegistries.length} Registries?`
    }
    swal.fire({
      title: message,
      text: 'Once you click ok, will not be able to stop deployment!',
      icon: 'warning',
      showCancelButton: true
      // buttons: true,
      // dangerMode: true
    }).then(willDeploy => {
      if (willDeploy.isConfirmed) {
        if (selectedRegistries.length > 0) {
          dispatch(deploySiteRegistries(selectedRegistries))
        } else {
          dispatch(deploySiteRegistries(siteRegistries))
        }
      }
    })
  }

  const onDownloadRegistriesSlot = () => {
    isDownloadingSlotInfo = true
    let registryList = siteRegistries.slice()
    // filter by selected registries if applicable
    if (selectedRegistries.length > 0) {
      const selectedRegistryIds = selectedRegistries.map(function (item) {
        return item._id
      })
      registryList = registryList.filter(function (item) {
        return selectedRegistryIds.indexOf(item._id) !== -1
      })
    }
    let out = ''
    for (let i = 0; i < registryList.length; i++) {
      const r = registryList[i]

      // get the registry name
      out += 'Name:,' + r.name + (r.isSingleton ? ' (singleton)' : '') + '\n'
      out += 'Description:, ' + r.description + '\n'
      out += '\n'
      out += 'ID, Type, Location, Sizes, Responsive, Fluid, Has IVR\n'          
      console.log('Registry: ', r)
      for (const j in r.slots) {
        const s = r.slots[j]
        console.log('Slot: ', s)
        // build the list of sizes
        const sizes = []
        s.adSizes.forEach(size => {
          sizes.push(size.width + 'x' + size.height)
        })
        const slotIsFluid = s.isFluid ? 'Yes' : 'No'
        const slotHasIVR = s.hasInViewRefresh ? 'Yes' : 'No'
        const slotIsResponsive = s.isResponsive ? 'Yes' : 'No'
        const sizeList = sizes.join(',')

        // get the slot type and location display names
        const slotTypeInfo = slotTypes.find(
          slotType => slotType._id === s.slotTypeId
        )
        const slotTypeName = slotTypeInfo ? slotTypeInfo.displayName : ''
        const locationInfo = locations.find(
          location => location._id === s.locationId
        )
        const locationName = locationInfo ? locationInfo.displayName : ''

        // build the slot info entry
        out +=
          s.name +
          ',' +
          slotTypeName +
          ',' +
          locationName +
          ',"' +
          sizeList + 
          '",' +
          slotIsResponsive +
          ',' +
          slotIsFluid +
          ',' +
          slotHasIVR +
          '\n' 
      }
      out += '\n\n'

    }
    // use a junk type to force download
    const data = new window.Blob([out], { type: 'text/csv' })
    const link = document.createElement('a')
    link.download = site.name + '_registry_slots_' + Date.now() + '.csv'
    link.href = URL.createObjectURL(data)
    link.click()
    isDownloadingSlotInfo = false
  }

  const onAddRegistry = () => {
    const newRegistry = JSON.parse(JSON.stringify(newRegistryResource))
    newRegistry.siteId = site._id
    newRegistry.isSingleton = false
    newRegistry.isClone = false
    newRegistry.tagForChildDirectedTreatment = site.tagForChildDirectedTreatment
    if (site.safeFrameConfig) {
      newRegistry.safeFrameConfig = site.safeFrameConfig
      newRegistry.safeFrameSandboxMode = site.safeFrameSandboxMode
      newRegistry.safeFrameAllowOverlayExpansion = site.safeFrameAllowOverlayExpansion
      newRegistry.safeFrameAllowPushExpansion = site.safeFrameAllowPushExpansion
    }
    dispatch(addNewRegistry(newRegistry))
  }

  const onCopyRegistry = registry => {
    dispatch(cloneRegistryResource(registry, true))
  }

  // Add New Site
  const AddNewRegistry = registry => {
    dispatch(addSiteRegistry(registry))
  }

  const handleIsOnlyRegistries = e => {
    if (e.target.name === 'chkboxOnlyRegistriesNeedDeployed') {
      setIsOnlyRegistriesNeedDeployed(e.target.checked)
    } else if (e.target.name === 'chkboxOnlySingletonRegistries') {
      if (e.target.checked) {
        setIsOnlySingletonRegistries(e.target.checked)
        setIsOnlyNonSingletonRegistries(!e.target.checked)
      } else {
        setIsOnlySingletonRegistries(e.target.checked)
      }
    } else if (e.target.name === 'chkboxOnlyNonSingletonRegistries') {
      if (e.target.checked) {
        setIsOnlyNonSingletonRegistries(e.target.checked)
        setIsOnlySingletonRegistries(!e.target.checked)
      } else {
        setIsOnlyNonSingletonRegistries(e.target.checked)
      }
    }
  }

  // #region Buttons
  const deployButton = siteRegistry => {
    if (isDeploying && siteRegistry._id === selectedItemId) {
      return (
        <IconButton
          disabled
          aria-label='Deploying...'
          title='Deploying...'
          className={[commonClasses.btnWarning, commonClasses.mr_1].join(' ')}
        >
          <CircularProgress size={24} />
        </IconButton>
      )
    } else {
      return siteRegistry.isChangedSinceDeployment
        ? (
          <IconButton
            disabled={!isAdFuelVersionSelected}
            aria-label='Deploy'
            title='Deploy'
            className={[commonClasses.btnWarning, commonClasses.mr_1].join(' ')}
            onClick={() => {
              onDeploySiteRegistry(siteRegistry)
            }}
          >
            <OfflineBoltIcon />
          </IconButton>)
        : (
          <IconButton
            disabled={!isAdFuelVersionSelected}
            aria-label='Deployed'
            title='Deployed'
            className={[commonClasses.btnSuccess, commonClasses.mr_1].join(' ')}
            onClick={() => {
              onDeploySiteRegistry(siteRegistry)
            }}
          >
            <OfflineBoltIcon />
          </IconButton>)
    }
  }

  const deployRegistriesButton = () => {
    if (isDeploying) {
      return (
        <Button
          disabled
          variant='contained'
          title='Deploying...'
          className={[commonClasses.btnSuccess, commonClasses.m_2].join(' ')}
          startIcon={<CircularProgress size={24} />}
        >
          Deploy
        </Button>
      )
    } else {
      if (selectedRegistries.length > 0) {
        return (
          <>
            <Button
              disabled={!isAdFuelVersionSelected}
              variant='contained'
              title='Deploy'
              className={[commonClasses.btnSuccess, commonClasses.m_1].join(' ')}
              startIcon={<OfflineBoltIcon />}
              onClick={() => {
                onDeploySiteRegistries()
              }}
            >
              Deploy Selected
            </Button>
          </>
        )
      } else {
        return (
          <Button
            disabled={!isAdFuelVersionSelected}
            variant='contained'
            title='Deploy'
            className={[commonClasses.btnSuccess, commonClasses.m_1].join(' ')}
            startIcon={<OfflineBoltIcon />}
            onClick={() => {
              onDeploySiteRegistries()
            }}
          >
            Deploy
          </Button>
        )
      }
    }
  }

  const downloadRegistrySlotInfoButton = () => {
    if (isDownloadingSlotInfo) {
      return (
        <Button
          disabled
          variant='contained'
          title='Processing...'
          className={[commonClasses.btnSuccess, commonClasses.m_2].join(' ')}
          startIcon={<CircularProgress size={24} />}
        >
          Processing...
        </Button>
      )
    } else {
      return (
        <Button
          variant='contained'
          title='Download Registry Slot Info'
          className={[commonClasses.btnSuccess, commonClasses.m_1].join(' ')}
          startIcon={<SystemUpdateAltIcon />}
          onClick={() => {
            onDownloadRegistriesSlot()
          }}
        >
          Download Registry Slot Info
        </Button>
      )
    }
  }

  const onManageSlotsClick = () => {
    setDialog({
      content: <ManageSlotsDialog site={site} setOpenDialog={setOpenDialog} />,
      displayDialogAction: false
    })
    setOpenDialog(true)
  }

  const handleCustomTargetingForRegistrySave = bulkTargetings => {
    dispatch(
      updateSiteRegistrySettings(selectedRegistries, null, null, bulkTargetings, null)
    )
  }

  const deleteButton = siteRegistry => {
    if (isDeleting && siteRegistry._id === selectedItemId) {
      return (
        <IconButton aria-label='Removing...' title='Removing...'>
          <CircularProgress size={24} />
        </IconButton>
      )
    } else {
      return (
        <IconButton
          aria-label='Remove'
          title='Remove'
          className={commonClasses.btnDanger}
          onClick={() => {
            onDeleteRegistry(siteRegistry._id)
          }}
        >
          <DeleteIcon />
        </IconButton>
      )
    }
  }
  // #endregion

  const handleDialogClose = () => {
    dispatch(ToggleNewRegistryStatus())
  }

  const toggleChildDirectedTreatmentDialog = () => {
    dispatch(ToggleChildDirectedTreatmentDialog())
  }

  const toggleInViewRefreshDialog = () => {
    dispatch(ToggleInViewRefreshDialog())
  }

  const toggleCustomTargetingDialog = () => {
    dispatch(ToggleCustomTargetingDialog())
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = siteRegistries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n) => n)
      setSelectedRegistries(newSelecteds)
    } else { setSelectedRegistries([]) }
  }

  const handleSelectClick = (event, registry) => {
    const selectedIndex = selectedRegistries.findIndex(
      sRegistry => sRegistry._id === registry._id
    )
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRegistries, registry)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRegistries.slice(1))
    } else if (selectedIndex === selectedRegistries.length - 1) {
      newSelected = newSelected.concat(selectedRegistries.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRegistries.slice(0, selectedIndex),
        selectedRegistries.slice(selectedIndex + 1)
      )
    }
    setSelectedRegistries(newSelected)
  }

  const [betaTest] = useState(
    window.localStorage.getItem('beta') === 'true'
  )
  
  // #region useEffect
  useEffect(() => {
    dispatch(clearSlots())
    dispatch(fetchRocketeerSites())
    setTimeout(() => {
      setLoadingPage(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (registries) {
      const filteredSiteRegistries = registries.filter(registry => {
        let match = false
        if (isOnlyRegistriesNeedDeployed && registry.isChangedSinceDeployment === true) { match = true }
        if (!isOnlyNonSingletonRegistries && !isOnlySingletonRegistries) { match = true }
        if (isOnlyNonSingletonRegistries && registry.isSingleton === false) { match = true }
        if (isOnlySingletonRegistries && registry.isSingleton === true) { match = true }
        return match
      })
      setSiteRegistries(
        filteredSiteRegistries.map(registry => ({ ...registry }))
      )
      /// // THIS IS THE OLD FILTERING PROCESS /////
      // if (isOnlyRegistriesNeedDeployed) {
      //   if (!isOnlyNonSingletonRegistries && !isOnlySingletonRegistries) {
      //     const filteredSiteRegistries = registries.filter(
      //       registry => registry.isChangedSinceDeployment === true
      //     )
      //     setSiteRegistries(
      //       filteredSiteRegistries.map(registry => ({ ...registry }))
      //     )
      //   } else if (isOnlyNonSingletonRegistries) {
      //     const filteredSiteRegistries = registries.filter(
      //       registry =>
      //         registry.isSingleton === false &&
      //         registry.isChangedSinceDeployment === true
      //     )
      //     setSiteRegistries(
      //       filteredSiteRegistries.map(registry => ({ ...registry }))
      //     )
      //   } else if (isOnlySingletonRegistries) {
      //     const filteredSiteRegistries = registries.filter(
      //       registry =>
      //         registry.isSingleton === true &&
      //         registry.isChangedSinceDeployment === true
      //     )
      //     setSiteRegistries(
      //       filteredSiteRegistries.map(registry => ({ ...registry }))
      //     )
      //   }
      // } else {
      //   if (isOnlyNonSingletonRegistries) {
      //     const filteredSiteRegistries = registries.filter(
      //       registry => registry.isSingleton === false
      //     )
      //     setSiteRegistries(
      //       filteredSiteRegistries.map(registry => ({ ...registry }))
      //     )
      //   } else if (isOnlySingletonRegistries) {
      //     const filteredSiteRegistries = registries.filter(
      //       registry => registry.isSingleton === true
      //     )
      //     setSiteRegistries(
      //       filteredSiteRegistries.map(registry => ({ ...registry }))
      //     )
      //   } else {
      //     setSiteRegistries(registries.map(registry => ({ ...registry })))
      //   }
      // }
      dispatch(fetchSlotTypes())
      dispatch(fetchSlotLocations())
    }
  }, [
    registries,
    isOnlyRegistriesNeedDeployed,
    isOnlyNonSingletonRegistries,
    isOnlySingletonRegistries
  ])
  // #endregion

  useEffect(() => {
    if (site && site.adFuelVersionId) dispatch(ToggleIsAdFuelVersionSelected(true))
    else dispatch(ToggleIsAdFuelVersionSelected(false))
  }, [site])

  return (
    <div className='registries'>
      {site._id
        ? (
          <Breadcrumbs
            className={addClasses(commonClasses.pl_3, commonClasses.pb_1, loadingPage ? (commonClasses.disabledlink, commonClasses.opacity50) : null)}
            aria-label='breadcrumb'
          >
            {/* <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>
              {site.name ? site.name : 'Site'}
            </NavLink> */}
            {/* <NavLink
              activeStyle={{
                fontWeight: 'bold',
                textDecoration: 'underline'
              }}
              to={`/sites/${siteNameFormatter(site.name)}/registries`}
            >
              Registries
            </NavLink> */}
            {loadingPage
              ? <NavLink to={`/sites/${siteNameFormatter(site.name)}`} onClick={(event) => event.preventDefault()}>{site.name ? site.name : 'Site'} </NavLink>
              : <NavLink to={`/sites/${siteNameFormatter(site.name)}`}>{site.name ? site.name : 'Site'}</NavLink>}
            {loadingPage
              ? <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`} onClick={(event) => event.preventDefault()}>Registries</NavLink>
              : <NavLink activeStyle={{ fontWeight: 'bold', textDecoration: 'underline' }} to={`/sites/${siteNameFormatter(site.name)}/registries`}>Registries</NavLink>}
          </Breadcrumbs>
          )
        : null}
      <Container maxWidth='xl'>
        <Toast
          isOpen={toast.isOpen}
          variant={toast.variant}
          message={toast.message}
          onClose={handleCloseMessage}
        />
        {betaTest 
          ? (
            <NewRegistryDialogBeta
              id='adfuel-modules-dialog'
              isNew={isNew}
              handleDialogClose={handleDialogClose}
              handleSave={AddNewRegistry}
              useWOPR={site.useWOPR ? site.useWOPR : false}
            />
          ) 
          : (
            <NewRegistryDialog
              id='adfuel-modules-dialog'
              isNew={isNew}
              handleDialogClose={handleDialogClose}
              handleSave={AddNewRegistry}
              useWOPR={site.useWOPR ? site.useWOPR : false}
            />
          )
        }
        <ChildDirectedTreatmentDialog
          id='Child-Directed-Treatment-modules-dialog'
          selectedRegistries={selectedRegistries}
          siteName={site.name}
          isDialogOpen={isChildDirectedTreatmentDialogOpen}
          handleDialogClose={toggleChildDirectedTreatmentDialog}
        />
        <InViewRefreshDialog
          id='In-View-Refresh-modules-dialog'
          selectedRegistries={selectedRegistries}
          siteName={site.name}
          isDialogOpen={isInViewRefreshDialogOpen}
          handleDialogClose={toggleInViewRefreshDialog}
        />
        <CustomTargetingDialog
          id='Custom-Targeting-modules-dialog'
          dialogTitle={`${site.name} - Bulk Custom Targeting`}
          isDialogOpen={isCustomTargetingDialogOpen}
          handleDialogClose={toggleCustomTargetingDialog}
          handleSave={handleCustomTargetingForRegistrySave}
        />
        <Paper position='static'>
          <form autoComplete='off' noValidate>
            <CardHeader title={pageTitle} />
            <Divider />
            <CardContent className={commonClasses.cardBkClr}>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item md={4} xs={12}>
                      <TextField
                        style={{ width: '99%' }}
                        label='Find'
                        margin='dense'
                        name='itemFilter'
                        defaultValue={searchTerm}
                        variant='outlined'
                        onChange={onSearchChange}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        disable={loading.toString()}
                        variant='contained'
                        color='primary'
                        className={addClasses(classes.button, classes.spacer)}
                        startIcon={<Add />}
                        onClick={() => {
                          onAddRegistry()
                        }}
                      >
                        Add New Registry
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Button
                        variant='contained'
                        title='Manage Slots'
                        className={[
                          commonClasses.btnSuccess,
                          commonClasses.m_1
                        ].join(' ')}
                        startIcon={<EditIcon />}
                        onClick={() => {
                          onManageSlotsClick()
                        }}
                      >
                        Manage Slots
                      </Button>
                      {downloadRegistrySlotInfoButton()}
                    </Grid>
                    <Grid item md={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isOnlyRegistriesNeedDeployed === true}
                            color='primary'
                            value='true'
                            name='chkboxOnlyRegistriesNeedDeployed'
                            onChange={e => handleIsOnlyRegistries(e)}
                          />
                        }
                        label='Show only Registries which need to be deployed'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isOnlySingletonRegistries === true}
                            color='primary'
                            value='true'
                            name='chkboxOnlySingletonRegistries'
                            onChange={e => handleIsOnlyRegistries(e)}
                          />
                        }
                        label='Show only Singleton Registries'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isOnlyNonSingletonRegistries === true}
                            color='primary'
                            value='true'
                            name='chkboxOnlyNonSingletonRegistries'
                            onChange={e => handleIsOnlyRegistries(e)}
                          />
                        }
                        label='Show only Non-Singleton Registries'
                      />
                    </Grid>
                    <Grid item md={2}>
                      {deployRegistriesButton()}
                      {selectedRegistries.length > 0
                        ? (
                          <FormControlLabel
                            className={commonClasses.p_1}
                            labelPlacement='start'
                            control={
                              <Avatar
                                className={[
                                  commonClasses.clrInfo,
                                  commonClasses.small
                                ].join(' ')}
                              >
                                {selectedRegistries.length}
                              </Avatar>
                          }
                            label={
                              <Typography style={{ fontWeight: 'bold' }}>
                                # Selected:{' '}
                              </Typography>
                          }
                          />
                          )
                        : (
                          <></>
                          )}
                    </Grid>
                    <Grid item md={6}>
                      {selectedRegistries.length > 0
                        ? (
                          <>
                            <Button
                              variant='contained'
                              title='Edit Selected Child-Directed Treatment Settings'
                              className={[
                                commonClasses.btnSuccess,
                                commonClasses.m_1
                              ].join(' ')}
                              startIcon={<EditIcon />}
                              onClick={() => {
                                toggleChildDirectedTreatmentDialog()
                              }}
                            >
                              Edit Selected Child-Directed Treatment Settings
                            </Button>
                            <Button
                              variant='contained'
                              title='Edit Selected In-View Refresh Settings'
                              className={[
                                commonClasses.btnSuccess,
                                commonClasses.m_1
                              ].join(' ')}
                              startIcon={<EditIcon />}
                              onClick={() => {
                                toggleInViewRefreshDialog()
                              }}
                            >
                              Edit Selected In-View Refresh Settings
                            </Button>
                            <Button
                              variant='contained'
                              title='Edit Selected Custom Targeting'
                              className={[
                                commonClasses.btnSuccess,
                                commonClasses.m_1
                              ].join(' ')}
                              startIcon={<EditIcon />}
                              onClick={() => {
                                toggleCustomTargetingDialog()
                              }}
                            >
                              Edit Selected Custom Targeting
                            </Button>
                          </>
                          )
                        : (
                          <></>
                          )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Divider /><br />
              {
                !isAdFuelVersionSelected
                  ? (
                    <><Card><CardContent><Grid item md={12} xs={12}>
                      <MuiAlert severity='warning'>
                        *** WARNING *** AdFuel Version is not selected for the site!
                      </MuiAlert>
                      <Divider />
                    </Grid></CardContent></Card>
                    <Divider /><br /></>
                    )
                  : (<></>)
              }
              <Card>
                <CardContent>
                  <Grid>
                    <Table size='small'>
                      <TableHead>
                        <TableRow>
                          <TableCell padding='checkbox'>
                            <Checkbox
                              color='primary'
                              indeterminate={selectedRegistries.length > 0 && selectedRegistries.length < rowsPerPage}
                              checked={rowsPerPage > 0 && selectedRegistries.length === rowsPerPage}
                              onClick={e => {
                                handleSelectAllClick(e)
                              }}
                              inputProps={{
                                'aria-label': 'select all registries'
                              }}
                            />
                          </TableCell>
                          <TableCell style={{ width: '25%', fontWeight: 'bold' }}>
                            Name
                          </TableCell>
                          <TableCell style={{ width: '35%', fontWeight: 'bold' }}>
                            Description
                          </TableCell>
                          <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                            Singleton
                          </TableCell>
                          <TableCell style={{ width: '10%', fontWeight: 'bold' }}>
                            Slots
                          </TableCell>
                          <TableCell style={{ width: '20%', fontWeight: 'bold' }}>
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {siteRegistries.length > 0
                          ? (
                              (rowsPerPage > 0
                                ? siteRegistries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : siteRegistries
                              ).map(siteRegistry => {
                                const isItemSelected = selectedRegistries.findIndex(sRegistry => sRegistry._id === siteRegistry._id) !== -1
                                const labelId = `checkbox-${siteRegistry._id}`
                                return (
                                  <TableRow name='siteRow' id={`siteRow_${siteRegistry._id}`} key={siteRegistry._id} hover>
                                    <TableCell padding='checkbox'>
                                      <Checkbox
                                        color='primary'
                                        checked={isItemSelected}
                                        name={labelId}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        onClick={event => {
                                          handleSelectClick(event, siteRegistry)
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell
                                      onClick={() => onRowClick(siteRegistry)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className={classes.plainText}>
                                        {siteRegistry.name}{site.useWOPR && siteRegistry.templateName ? (<><br /><sup>[{siteRegistry.templateName}]</sup></>) : ''}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      onClick={() => onRowClick(siteRegistry)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className={classes.plainText}>
                                        {siteRegistry.description}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      onClick={() => onRowClick(siteRegistry)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className={classes.plainText}>
                                        {siteRegistry.isSingleton ? 'Yes' : 'No'}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      onClick={() => onRowClick(siteRegistry)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div className={classes.plainText}>
                                        {siteRegistry.numberOfSlots}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      {deployButton(siteRegistry)}
                                      <IconButton
                                        disable={loading.toString()}
                                        aria-label='Copy'
                                        title='Copy'
                                        className={[
                                          commonClasses.btnSuccess,
                                          commonClasses.mr_1
                                        ].join(' ')}
                                        onClick={() => {
                                          onCopyRegistry(siteRegistry)
                                        }}
                                      >
                                        <FileCopyIcon />
                                      </IconButton>
                                      {deleteButton(siteRegistry)}
                                    </TableCell>
                                  </TableRow>
                                )
                              })
                            )
                          : (
                            <TableRow>
                              <TableCell colSpan={6}>
                                {siteRegistries.length === 0 && loading && (
                                  <LoadingProgress circular={false} label='Loading Registries' />
                                )}
                              </TableCell>
                            </TableRow>
                            )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[
                              10,
                              25,
                              50,
                              100,
                              { label: 'All', value: siteRegistries?.length }
                            ]}
                            colSpan={6}
                            count={siteRegistries.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                              inputProps: { 'aria-label': 'rows per page' },
                              native: true
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Grid>
                </CardContent>
              </Card>
            </CardContent>
          </form>
        </Paper>
      </Container>
      <CustomDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        title={dialog.title}
        subtitle={dialog.subtitle}
        content={dialog.content}
        displayDialogAction={dialog.displayDialogAction}
        customAction={dialog.customAction}
        size={dialog.size}
      />
    </div>
  )
}

export default Registries
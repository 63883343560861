import React from 'react'

import { Card, CardContent, FormControlLabel, Checkbox, Table, TableHead, TableBody, TableRow, TableCell, TextField, Grid, Typography } from '@material-ui/core'

export const A9 = ({ options, allOptions, setOptions, setAllOptions, errors, setErrors, validation }) => {
  let { A9 } = options
  A9.ENABLE_SIGNALS = { DOM: false, INTL: false, ...A9.ENABLE_SIGNALS }
  const { TIMEOUTS, ENABLE_SIGNALS } = A9
  const { DESKTOP, MOBILE } = TIMEOUTS
  const { NUMBER } = validation
  const { TIMEOUT } = NUMBER  

  const displayEnableSignalsTable = () => {
    return (
      <Table size='small' aria-label='a9-enable-signals-table'>
        <TableBody>
          <TableRow>
            <TableCell><Typography style={{ fontWeight: 800 }} variant='h5'>Enable Signals?</Typography></TableCell>
            <TableCell>
              <Grid container spacing={2}>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color='primary'
                        checked={ENABLE_SIGNALS.DOM}
                        onChange={(event) => {
                          A9.ENABLE_SIGNALS.DOM = event.target.checked
                          setOptions({ ...options, A9 })
                          setAllOptions({ ...allOptions, A9 })
                        }}
                      />
                    )}
                    label='Domestic'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        color='primary'
                        checked={ENABLE_SIGNALS.INTL}
                        onChange={(event) => {
                          A9.ENABLE_SIGNALS.INTL = event.target.checked
                          setOptions({ ...options, A9 })
                          setAllOptions({ ...allOptions, A9 })
                        }}
                      />
                    )}
                    label='International'
                  />
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }
  
  return (
    <Card>
      <CardContent>
        <Table size='small' aria-label='a9 timeouts table'>
          <TableHead>
            <TableRow>
              <TableCell><Typography style={{ fontWeight: 800 }} variant='h5'>Timeouts</Typography></TableCell>
              <TableCell>Desktop</TableCell>
              <TableCell>Mobile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Display</TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Initial'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={DESKTOP.DISPLAY.INITIAL}
                      error={errors.A9_DDI}
                      helperText={errors.A9_DDI && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.DESKTOP.DISPLAY.INITIAL = value
                        setErrors({ ...errors, A9_DDI: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Refresh'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={DESKTOP.DISPLAY.REFRESH}
                      error={errors.A9_DDR}
                      helperText={errors.A9_DDR && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.DESKTOP.DISPLAY.REFRESH = value
                        setErrors({ ...errors, A9_DDR: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Initial'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={MOBILE.DISPLAY.INITIAL}
                      error={errors.A9_MDI}
                      helperText={errors.A9_MDI && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.MOBILE.DISPLAY.INITIAL = value
                        setErrors({ ...errors, A9_MDI: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Refresh'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={MOBILE.DISPLAY.REFRESH}
                      error={errors.A9_MDR}
                      helperText={errors.A9_MDR && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.MOBILE.DISPLAY.REFRESH = value
                        setErrors({ ...errors, A9_MDR: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Video</TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Initial'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={DESKTOP.VIDEO.INITIAL}
                      error={errors.A9_DVI}
                      helperText={errors.A9_DVI && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.DESKTOP.VIDEO.INITIAL = value
                        setErrors({ ...errors, A9_DVI: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Refresh'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={DESKTOP.VIDEO.REFRESH}
                      error={errors.A9_DVR}
                      helperText={errors.A9_DVR && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.DESKTOP.VIDEO.REFRESH = value
                        setErrors({ ...errors, A9_DVR: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Midroll'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={DESKTOP.VIDEO.MIDROLL}
                      error={errors.A9_DVM}
                      helperText={errors.A9_DVM && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.DESKTOP.VIDEO.MIDROLL = value
                        setErrors({ ...errors, A9_DVM: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell>
                <Grid container spacing={2}>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Initial'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={MOBILE.VIDEO.INITIAL}
                      error={errors.A9_MVI}
                      helperText={errors.A9_MVI && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.MOBILE.VIDEO.INITIAL = value
                        setErrors({ ...errors, A9_MVI: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Refresh'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={MOBILE.VIDEO.REFRESH}
                      error={errors.A9_MVR}
                      helperText={errors.A9_MVR && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.MOBILE.VIDEO.REFRESH = value
                        setErrors({ ...errors, A9_MVR: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      margin='none'
                      label='Midroll'
                      inputProps={{
                        type: 'number',
                        min: TIMEOUT.min,
                        max: TIMEOUT.max
                      }}
                      value={MOBILE.VIDEO.MIDROLL}
                      error={errors.A9_MVM}
                      helperText={errors.A9_MVM && TIMEOUT.message}
                      onChange={(event) => {
                        const value = parseInt(event.target.value)
                        A9.TIMEOUTS.MOBILE.VIDEO.MIDROLL = value
                        setErrors({ ...errors, A9_MVM: value < TIMEOUT.min || value > TIMEOUT.max })
                        setOptions({ ...options, A9 })
                        setAllOptions({ ...allOptions, A9 })
                      }}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {displayEnableSignalsTable()}
      </CardContent>
    </Card>
  )
}

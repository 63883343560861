import React, { forwardRef } from 'react'

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  Delete,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  FileCopyRounded,
  CloudUploadRounded,
  StopRounded
} from '@material-ui/icons'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LaunchIcon from '@material-ui/icons/Launch'
import FileCopyIcon from '@material-ui/icons/FileCopy'

export const TableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} style={{ color: '#D22' }} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={{ color: '#e5bc73' }} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  FileCopyRounded: forwardRef((props, ref) => <FileCopyRounded {...props} ref={ref} />),
  CloudUploadRounded: forwardRef((props, ref) => <CloudUploadRounded {...props} ref={ref} />),
  AssignmentIndIcon: forwardRef((props, ref) => <AssignmentIndIcon {...props} ref={ref} />),
  LaunchIcon: forwardRef((props, ref) => <LaunchIcon {...props} ref={ref} />),
  Clone: forwardRef((props, ref) => <FileCopyIcon {...props} ref={ref} />),
  StopRounded: forwardRef((props, ref) => <StopRounded {...props} ref={ref} />)
}

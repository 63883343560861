import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from '../modules'
import { createBrowserHistory } from 'history'
import {
  // reset as resetRegistries,
  fetchRegistriesForSite,
  fetchSiteRegistryById
} from '../modules/registrySlice'
import { fetchSiteById } from '../modules/sitesSlice'
import { fetchUserRoles } from '../modules/roleSlice'
import { fetchUsers } from '../modules/userSlice'
import { routerMiddleware } from 'connected-react-router'
import { fetchAdFuelVersions } from '../modules/adFuelSlice'
import { fetchAdFuelModules } from '../modules/adFuelModuleSlice'
import { fetchBannerMessage } from '../modules/bannerMessageSlice'
import { fetchSiteCategories } from '../modules/siteCategorySlice'
import { fetchNotifications } from '../modules/reportNotificationsSlice'
import { fetchSlotTypes } from '../modules/slotTypesSlice'
import { fetchSlotLocations } from '../modules/slotLocationSlice'
// import { getPath } from '../modules/configSlice'
import { fetchAllAdUnits, getCacheTimeStamps } from '../modules/DFPAdUnitSlice'
import { fetchAdCDNFolders } from '../modules/AdLoaderCDNFoldersSlice'
import {
  siteNameFormatter,
  registryNameFormatter,
  hasRole
} from '../utilities/helperFunctions'

export const ROUTE_LOCATION_DID_UPDATE = 'store/ROUTE_LOCATION_DID_UPDATE'

export const history = createBrowserHistory()

const initialState = {
  // router: routerReducer
}

const logger = store => next => action => {
  if (window) {
    if (action) {
      const result = next(action)
      return result
    } else {
      return next()
    }
  }
}

const initialDataMiddleware = store => next => action => {
  // console.log('action.type', action.type)
  let path = window.location.pathname
  const user = store.getState().authReducer.user
  const pages = path.split('/')
  // const token = store.getState().authReducer.token
  if (action) {
    switch (action.type) {
      case 'auth/SET_LOGIN_SUCCESS':
        break
      // case 'registries/GET_SELECTED_REGISTRY_SUCCESS':
      //   siteId = action.selectedRegistry.siteId || null
      //   if (siteId) store.dispatch(fetchSiteById(siteId))
      //   break
      case 'sitesSlice/FetchRocketeerSites':
        if (path.match(/\/sites\//)) {
          const siteName = siteNameFormatter(pages[2])
          const site = action.payload.find(
            site => siteNameFormatter(site.name) === siteName
          )
          const siteId = site ? site._id : null
          if (siteId) {
            store.dispatch(fetchSiteById(siteId))
            store.dispatch(fetchRegistriesForSite(siteId))
          }
        }
        break
      case 'registrySlice/FetchSiteRegistries':
        if (path.match(/\/registries\/registry\//)) {
          console.log('New Registry path')
          const registryName = registryNameFormatter(pages[5])
          const registry = action.payload.find(
            item => {
              return registryNameFormatter(item.name, item.templateName) === registryName && !item.isSingleton
            }
          )
          const registryId = registry ? registry._id : null
          if (registryId) {
            console.log('Registry: ', registry)
            store.dispatch(fetchSiteRegistryById(registryId))
            store.dispatch(fetchSlotTypes())
            store.dispatch(fetchSlotLocations())
          }
        } else if (path.match(/\/registries\/singleton\//)) {
          console.log('Singleton path')
          const registryName = registryNameFormatter(pages[5])
          const registry = action.payload.find(
            item => registryNameFormatter(item.name, item.templateName) === registryName && item.isSingleton
          )
          const registryId = registry ? registry._id : null
          if (registryId) {
            console.log('Singleton: ', registry)
            store.dispatch(fetchSiteRegistryById(registryId))
            store.dispatch(fetchSlotTypes())
            store.dispatch(fetchSlotLocations())
          }
        } else if (path.match(/\/registries\//)) {
          console.log('OG Registry path')
          const registryName = registryNameFormatter(pages[4])          
          const registry = action.payload.find(
            item => registryNameFormatter(item.name, item.templateName) === registryName
          )
          const registryId = registry ? registry._id : null
          if (registryId) {
            console.log('Registry/Singleton: ', registry)
            store.dispatch(fetchSiteRegistryById(registryId))
            store.dispatch(fetchSlotTypes())
            store.dispatch(fetchSlotLocations())
          }
        } 
        break
      default:
        break
    }
  }
  next(action)
  if (action) {
    switch (action.type) {
      case '@@router/LOCATION_CHANGE':
        path = action.payload.location.pathname
        store.dispatch(fetchBannerMessage())
        switch (true) {
          case path === '/admin/adFuelVersions':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchAdFuelVersions())
            }
            break
          case path === '/admin/adFuelModules':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchAdFuelModules())
              store.dispatch(fetchAdFuelVersions())
            }
            break
          case path === '/admin/categories':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchSiteCategories())
            }
            break
          case path === '/admin/cdnFolders':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchAdCDNFolders())
            }
            break
          case path === '/admin/reports':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchNotifications())
            }
            break
          case path === '/admin/slotTypes':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchSlotTypes())
              store.dispatch(fetchSlotLocations())
            }
            break
          case path === '/admin/locations':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchSlotLocations())
            }
            break
          case path === '/admin/dfpAdmin':
            if (hasRole(['admin'], user)) {
              store.dispatch(getCacheTimeStamps())
            }
            break
          case path === '/admin/bannerMessage':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchBannerMessage())
            }
            break
          case path === '/admin/emailUsers':
            if (hasRole(['admin'], user)) {
              store.dispatch(fetchUsers())
              store.dispatch(fetchUserRoles())
            }
            break
          case path === '/support/customTargets':
            store.dispatch(fetchAllAdUnits())
            break
          case path === '/':
            // store.dispatch(getPath())
            // store.dispatch(fetchBannerMessage())
            break
          default:
            break
        }
        break
      default:
        break
    }
  }
}

const store = configureStore({
  reducer: rootReducer(history),
  devTools: true,
  middleware: [
    logger,
    initialDataMiddleware,
    routerMiddleware(history),
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
      devTools: true
    })
  ],
  preloadedState: initialState
})

// const routeLocationDidUpdate = (location) => {
//   return {
//     type: ROUTE_LOCATION_DID_UPDATE,
//     path: location.pathname
//   }
// }

// history.listen(location => store.dispatch(routeLocationDidUpdate(location)))

export default store
